import React from 'react';
import PropTypes from 'prop-types';
import { Field } from './Field';

/**
 * List of checkboxes.
 * @param name
 * @param options
 * @param valueKey
 * @param labelKey
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
export const CheckboxListField = ({ name, options, valueKey, labelKey, ...rest }) => {
    console.log(name, rest);
    return (
        <ul className="o-list-bare u-margin-none u-line-height-default" aria-labelledby={rest['data-has-label'] ? name : null} role="group">
            {options.map((option, key) => (
                <li className="o-list-bare__item u-margin-vertical-tiny" key={key}>
                    <label>
                        <Field type="checkbox" name={name} id={`${name}-${key}`} value={option[valueKey]} disabled={option?.disabled ?? false} {...rest} />
                        {option[labelKey]}
                    </label>
                </li>
            ))}
        </ul>
    );
};

CheckboxListField.propTypes = {
    name: PropTypes.string.isRequired,
    /**
     * List of options for checkboxes.
     */
    options: PropTypes.array.isRequired,
    /**
     * Key to be used for value.
     */
    valueKey: PropTypes.string,
    /**
     * Key to be used for label.
     */
    labelKey: PropTypes.string,
};

CheckboxListField.defaultProps = {
    valueKey: 'value',
    labelKey: 'label',
};
